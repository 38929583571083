import { throttle } from '../functions/throttle.js';

const control = document.querySelector('.control')

if (control) {
    const controlView = control.querySelector('#button-view')
    const controlFilter = control.querySelector('#button-filter')
    const controlForm = control.querySelector('.form-control')
    const controlBody = control.querySelector('.form-control__body')
    const catalogList = document.querySelector('.catalog__list')

    controlView.addEventListener('click', () => {
        controlView.classList.toggle('control__button--active')
        if (controlView.classList.contains('control__button--active')) {
            catalogList.classList.add('catalog__list--grid')
        } else {
            catalogList.classList.remove('catalog__list--grid')
        }
    })

    controlFilter.addEventListener('click', () => {
        controlFilter.classList.toggle('control__button--active')
        if (controlFilter.classList.contains('control__button--active')) {
            controlForm.classList.add('control__form--active')
        } else {
            controlForm.classList.remove('control__form--active')
        }
    })

    setControlBodyHeight();

    let throttleSetControlBodyHeight = throttle(() => {
        setControlBodyHeight();
    });

    window.addEventListener('resize', throttleSetControlBodyHeight);

    function setControlBodyHeight() {
        controlBody.removeAttribute('style')
        if (control.offsetWidth <= controlForm.offsetWidth) {
            controlBody.style.maxHeight = '100%'
        }
    }
}