const basketButton = document.querySelector('#button-basket')

if (basketButton) {
    const basketTitle = document.querySelector('.basket__title');
    const basketForm = document.querySelector('.basket__form');
    const basketList = document.querySelector('.basket__list');
    const basketPriceTotal= document.querySelector('#basket-price-total')
    const basketButtonNull = document.querySelector('#basket-button-null');
    const basketButtonMore = document.querySelector('#basket-button-more');
    const basketButtonCount = basketButton.querySelector('span')
    const basketButtonMoreCount = basketButtonMore.querySelector('.button__count');

    // Массив товаров для примера (можно динамически загружать с сервера)
    const initialProducts = [
        { id: 1, link: '#', image: './img/catalog/card-01.png', title: 'Куртка Авиатор в меху', price: 350000, quantity: 1 },
        { id: 2, link: '#', image: './img/catalog/card-02.png', title: 'Портфель Voyage “Над городом”', price: 750000, quantity: 1 },
        { id: 3, link: '#', image: './img/catalog/card-03.png', title: 'Тренч из Питона', price: 680000, quantity: 1 },
        { id: 4, link: '#', image: './img/catalog/card-04.png', title: 'Дубленка Nude Норка', price: 1150000, quantity: 2 },
        { id: 5, link: '#', image: './img/catalog/card-04.png', title: 'Дубленка Nude Норка', price: 1150000, quantity: 2 },
        { id: 5, link: '#', image: './img/catalog/card-04.png', title: 'Дубленка Nude Норка', price: 1150000, quantity: 2 },
    ];

    // Инициализация корзины из localStorage
    const cartItems = JSON.parse(localStorage.getItem('cart')) || initialProducts;

    // Количество товаров видимых в корзине
    let viewMoreCount = 4;

    // Функция рендеринга товаров в корзине
    function renderCart() {
        basketList.innerHTML = ''; // Очистить содержимое корзины

        cartItems.forEach((item, index) => {

            // Прервать добавление в корзину, если достигнут лимит
            if (index >= viewMoreCount) return;

            const itemElement = document.createElement('li');
            itemElement.classList.add('basket__item', 'item-basket');

            itemElement.innerHTML = `
                <img width="35" height="40" class="item-basket__image img" src="${item.image}" alt="${item.title}">
                <a href="${item.link}" class="item-basket__link">
                  <h3 class="item-basket__title">${item.title}</h3>
                </a>
                <div class="item-basket__quantity quantity">
                  <button class="quantity__button quantity__button--decrement" data-index="${index}" aria-label="Убавить количество"></button>
                  <span class="quantity__count">${item.quantity}</span>
                  <button class="quantity__button quantity__button--increment" data-index="${index}" aria-label="Добавить количество"></button>
                </div>
                <output class="item-basket__output" name="Цена: ">${formatNumber(item.price)}</output>
                <button class="item-basket__remove" data-index="${index}" aria-label="Удалить товар"></button>
            `;

            basketList.appendChild(itemElement);
        });

        updateMoreButton();
        updateBasketMode();
        updateTotalPrice();
        updateTotalQuantity();
    }

    // Функция обновления количества товара
    function updateQuantity(index, change) {
        cartItems[index].quantity += change;
        if (cartItems[index].quantity < 1) cartItems[index].quantity = 1;
        saveCart();
        renderCart();
    }

    // Функция удаления товара
    function removeItem(index) {
        cartItems.splice(index, 1);
        saveCart();
        renderCart();
    }

    // Функция сохранения корзины в localStorage
    function saveCart() {
        localStorage.setItem('cart', JSON.stringify(cartItems));
    }

    // Функция обновления общей суммы корзины
    function updateTotalPrice() {
        const totalPrice = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
        basketPriceTotal.textContent = formatNumber(totalPrice);
    }

    // Функция установки режима корзины
    function updateBasketMode() {
        if (cartItems.length === 0) {
            basketTitle.textContent = 'Ваша корзина пустая'
            basketForm.style.display = 'none';
            basketButtonNull.style.display = 'flex';
        } else {
            basketTitle.textContent = 'Ваша корзина'
            basketForm.style.display = 'block';
            basketButtonNull.style.display = 'none';
        }
    }

    // Функция обновления кнопки "смотреть еще"
    function updateMoreButton() {
        const forms = ['позицию', 'позиции', 'позиций'];
        const countView = cartItems.length - viewMoreCount;

        basketButtonMoreCount.textContent = `${countView} ${getDeclension(countView, forms)}`;

        if (cartItems.length > viewMoreCount) {
            basketButtonMore.style.display = 'flex';
        } else {
            basketButtonMore.style.display = 'none';
        }
    }

    // Функция обновления количества товара в иконке корзины
    function updateTotalQuantity() {
        const getTotalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
        basketButtonCount.textContent = getTotalQuantity.toString();

        if (getTotalQuantity === 0) {
            basketButtonCount.classList.remove('header__count--fill');
        } else {
            basketButtonCount.classList.add('header__count--fill');
        }
    }

    // Функция извлекает число из строки
    function extractNumber(str) {
        return parseInt(str.replace(/[^\d]/g, ''), 10);
    }

    // Функция форматирует число в строку с пробелами через каждые три знака
    function formatNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ₽';
    }

    // Функция возвращает правильную форму слова "позиция" в зависимости от числа
    function getDeclension(number, forms) {
        const [one, few, many] = forms;
        const mod10 = number % 10;
        const mod100 = number % 100;

        if (mod100 >= 11 && mod100 <= 19) {
            return many; // 11-19 — всегда форма "позиций"
        }

        if (mod10 === 1) {
            return one; // 1, 21, 31... — форма "позиция"
        }

        if (mod10 >= 2 && mod10 <= 4) {
            return few; // 2-4, 22-24, 32-34... — форма "позиции"
        }

        return many; // Все остальные случаи — форма "позиций"
    }

    // События для кнопок изменения количества и удаления товаров
    basketForm.addEventListener('click', (event) => {
        if (event.target.classList.contains('quantity__button--increment')) {
            const index = event.target.getAttribute('data-index');
            updateQuantity(index, 1);
        }

        if (event.target.classList.contains('quantity__button--decrement')) {
            const index = event.target.getAttribute('data-index');
            updateQuantity(index, -1);
        }

        if (event.target.classList.contains('item-basket__remove')) {
            const index = event.target.getAttribute('data-index');
            removeItem(index);
        }

        if (event.target.closest('#basket-button-more')) {
            viewMoreCount = cartItems.length;
            renderCart();
        }
    });

    // Событие для кнопки Корзины
    basketButton.addEventListener('click', (event) => {
        viewMoreCount = 4
        renderCart()
    })

    // Первоначальный рендеринг корзины
    renderCart();
}