import Swiper from 'swiper';
import { throttle } from '../functions/throttle.js';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay]);

const showroom = document.querySelector('.showroom');
const newsOther = document.querySelector('.news--other');
const boutiquesGallery = document.querySelectorAll('.boutiques__gallery');

const heroSwiper = new Swiper('.hero__swiper', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: true,
   autoplay: {
      delay: 5000,
      disableOnInteraction: false,
   },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
});
const atelierSwiper = new Swiper('.atelier__swiper', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: true,
   autoplay: {
      delay: 5000,
      disableOnInteraction: false,
   },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
});
const bestsellersSwiper = new Swiper('.bestsellers__swiper', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: true,
   autoplay: {
      delay: 5000,
      disableOnInteraction: false,
   },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
   breakpoints: {
      621: {
         slidesPerView: 2,
      },
      961: {
         slidesPerView: 3,
      },
   },
});
const sliderSwiper = new Swiper('.slider__swiper', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: true,
   autoplay: {
       delay: 5000,
       disableOnInteraction: true
   },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
});
const sliderAboutSwiper = new Swiper('.slider-about__swiper', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: true,
   // autoplay: {
   //    delay: 5000,
   //    disableOnInteraction: true
   // },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
   on: {
      slideChange: function (swiper) {
         const activeSlide = swiper.slides[swiper.activeIndex];

         if (activeSlide.classList.contains('play--light')) {
            swiper.el.classList.add('swiper--light');
         } else {
            swiper.el.classList.remove('swiper--light');
         }
      }
   }
});
const collectionsAboutSwiper = new Swiper('.collections__swiper--about', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: true,
   autoplay: {
      delay: 5000,
      disableOnInteraction: false,
   },
   navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
   },
   breakpoints: {
      621: {
         slidesPerView: 1,
      },
      961: {
         slidesPerView: 2,
      },
   },
});
const collectionsPartnersSwiper = new Swiper('.collections__swiper--partners', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: true,
   autoplay: {
      delay: 5000,
      disableOnInteraction: false,
   },
   navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
   },
   breakpoints: {
      621: {
         slidesPerView: 2,
      },
      961: {
         slidesPerView: 3,
      },
   },
});
const cardSwiper = new Swiper('.card__swiper', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: false,
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
});
const newsDetailSwiper = new Swiper('.news-details__swiper', {
   slidesPerView: 1,
   spaceBetween: 20,
   loop: true,
   autoplay: {
      delay: 5000,
      disableOnInteraction: false,
   },
   navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
   },
   breakpoints: {
      621: {
         slidesPerView: 2,
         spaceBetween: 20,
      },
      961: {
         slidesPerView: 3,
         spaceBetween: 40,
      },
   },
});
const heroPartnersSwiper = new Swiper('.hero-partners__swiper', {
   slidesPerView: 1,
   spaceBetween: 0,
   loop: true,
   autoplay: {
      delay: 5000,
      disableOnInteraction: false,
   },
   breakpoints: {
      769: {
         slidesPerView: 2,
      },
   },
});

if (showroom) {
   const parentSwiper = showroom.querySelector('.showroom__container');
   const slidesArrClassName = 'showroom__slide';
   const slidesArr = parentSwiper.querySelectorAll('.' + slidesArrClassName);

   let swiper = undefined;

   initSwiper();

   let throttleInitSwiper = throttle(() => {
      initSwiper();
   });

   window.addEventListener('resize', throttleInitSwiper);

   function initSwiper() {
      const isTablet = window.innerWidth <= 1024;

      if (isTablet && !swiper) {
         createSwiper(parentSwiper, slidesArr, true);

         swiper = new Swiper(parentSwiper.querySelector('.swiper'), {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            autoplay: {
               delay: 5000,
               disableOnInteraction: true,
            },
            navigation: {
               nextEl: '.swiper-button-next',
               prevEl: '.swiper-button-prev',
            },
            breakpoints: {
               621: {
                  spaceBetween: 30,
               },
            },
         });
      } else if (!isTablet && swiper) {
         removeSwiper(parentSwiper, slidesArr, slidesArrClassName);
         swiper = undefined;
      }
   }
}
if (newsOther) {
   const parentSwiper = newsOther.querySelector('.news__list');
   const slidesArrClassName = 'news__item';
   const slidesArr = parentSwiper.querySelectorAll('.' + slidesArrClassName);

   let swiper = undefined;

   initSwiper();

   let throttleInitSwiper = throttle(() => {
      initSwiper();
   });

   window.addEventListener('resize', throttleInitSwiper);

   function initSwiper() {
      const isTabletNotMobile = window.innerWidth <= 960 && !(window.innerWidth <= 620);

      if (isTabletNotMobile && !swiper) {
         createSwiper(parentSwiper, slidesArr);

         swiper = new Swiper(parentSwiper.querySelector('.swiper'), {
            slidesPerView: 1.3,
            spaceBetween: 30,
            loop: true,
            autoplay: {
               delay: 5000,
               disableOnInteraction: true,
            },
         });
      } else if (!isTabletNotMobile && swiper) {
         removeSwiper(parentSwiper, slidesArr, slidesArrClassName);
         swiper = undefined;
      }
   }
}
if (boutiquesGallery.length > 0) {
   boutiquesGallery.forEach((item) => {
      const parentSwiper = item;
      const slidesArrClassName = 'boutiques__slide';
      const slidesArr = parentSwiper.querySelectorAll('.' + slidesArrClassName);

      let swiper = undefined;

      initSwiper();

      let throttleInitSwiper = throttle(() => {
         initSwiper();
      });

      window.addEventListener('resize', throttleInitSwiper);

      function initSwiper() {
         const isTablet = window.innerWidth <= 960;

         if (isTablet && !swiper) {
            createSwiper(parentSwiper, slidesArr, true);

            swiper = new Swiper(parentSwiper.querySelector('.swiper'), {
               slidesPerView: 1,
               spaceBetween: 20,
               loop: true,
               autoplay: {
                  delay: 5000,
                  disableOnInteraction: false,
               },
               navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
               },
            });
         } else if (!isTablet && swiper) {
            removeSwiper(parentSwiper, slidesArr, slidesArrClassName);
            swiper = undefined;
         }
      }
   })
}

function createSwiper(parentSwiper, slidesArr, navigation = false) {
   const swiper = document.createElement('div');
   const swiperWrapper = document.createElement('div');

   swiper.classList.add('swiper');
   swiperWrapper.classList.add('swiper-wrapper');

   slidesArr.forEach((item) => {
      item.classList.add('swiper-slide');
      swiperWrapper.appendChild(item);
   });
   swiper.appendChild(swiperWrapper);

   if (navigation) {
      const prev = document.createElement('div');
      const next = document.createElement('div');

      prev.classList.add('swiper-button-prev');
      next.classList.add('swiper-button-next');

      swiper.appendChild(prev);
      swiper.appendChild(next);
   }

   parentSwiper.appendChild(swiper);
}
function removeSwiper(parentSwiper, slidesArr, slidesArrClassName) {
   const swiper = parentSwiper.querySelector('.swiper');
   slidesArr.forEach((item) => {
      item.removeAttribute('style');
      item.removeAttribute('class');
      item.removeAttribute('data-swiper-slide-index');
      item.classList.add(slidesArrClassName);
      parentSwiper.appendChild(item);
   });
   swiper.remove();
}
