import './components/getScrollBarWidth.js';
import './components/header.js';
import './components/headerNav.js';
import './components/inputSubscribe.js';
import './components/swiper.js';
import './components/videoPlay.js';
import './components/fancybox.js';
import './components/map.js';
import './components/smoothScroll.js';
import './components/control.js';
import './components/basket.js';
import './components/masker.js';
import './components/getCode.js';
import './components/datePicker.js';
import './components/confirmPassword.js';
import './components/checkInput.js';
import './components/modal.js';
