import { burger } from '../functions/burger.js';
import { getHeaderHeight } from '../functions/header-height.js';
import { getScrollBarWidth } from '../functions/scrollBar-width.js';
import { throttle } from '../functions/throttle.js';

const body = document.querySelector('body');
const header = document.querySelector('#header');
const main = document.querySelector('#main');
const headerSlim = header.classList.contains('header--slim');
const headerLight = header.classList.contains('header--light');

setMainTop();
getHeaderHeight();
getScrollBarWidth();
setClassOnScroll(header, headerSlim, 'header--slim', true, 20);
removeClassOnScroll(header, headerLight, 'header--light', 20);

let throttleGetHeaderHeight = throttle(() => {
    getHeaderHeight()
});
let throttleSetClassOnScroll = throttle(() => {
    setClassOnScroll(header, headerSlim, 'header--slim', 20);
    removeClassOnScroll(header, headerLight, 'header--light', 20);
});

window.addEventListener('resize', throttleGetHeaderHeight);
window.addEventListener('scroll', throttleSetClassOnScroll);

function setClassOnScroll (element, isClassName, className, heightOffset) {
    const isBodyTop = body.classList.contains('disable-scroll') ? true : false;

    if (window.scrollY > heightOffset && !isClassName) {
        element.classList.add(className);
    } else if (!isBodyTop && !isClassName) {
        element.classList.remove(className);
    }
}
function removeClassOnScroll (element, isClassName, className, heightOffset) {
    const isBodyTop = body.classList.contains('disable-scroll') ? true : false;

    if (window.scrollY > heightOffset && isClassName) {
        element.classList.remove(className);
    } else if (!isBodyTop && isClassName) {
        element.classList.add(className);
    }
}
function setMainTop() {
    if (!headerSlim) {
        main.classList.add('main--top');
    }
}