import VMasker from 'vanilla-masker';

const tel = document.querySelectorAll('input[type="tel"]');

if (tel.length) {
    const countryButton = document.querySelector('#form-button-intl');
    const countryDropdown = document.querySelector('#form-country-select');
    const countryOptions = countryDropdown?.querySelectorAll('.form__option');
    const telMaskList = {
        'RU': ['+9 999 999-99-99', '+9 999 999-99-99'],
        'BY': ['+999 99 999 9999', '+999 99 999 9999'],
        'KZ': ['+9 999 999-99-99', '+9 999 999-99-99'],
    }

    tel.forEach(tel => {
        VMasker(tel).maskPattern(telMaskList.RU[0]);
        tel.addEventListener('input', inputHandler.bind(undefined, telMaskList.RU, 11), false);
    })

    if (countryButton) {
        // Показать или скрыть список стран при клике на кнопку
        countryButton.addEventListener('click', function () {
            countryDropdown.classList.toggle('form__dropdown--hidden');
        });

        // Обработать выбор страны из списка
        countryOptions.forEach(option => {
            option.addEventListener('click', function () {
                const countryCode = option.dataset.code;
                const countryShortName = option.dataset.country;
                const telMask = telMaskList[countryShortName];

                // Обновить кнопку и placeholder
                countryButton.dataset.country = countryShortName;
                tel[0].placeholder = countryCode;

                VMasker(tel[0]).maskPattern(telMask[0]);
                tel[0].addEventListener('input', inputHandler.bind(undefined, telMask, 11), false);

                // Скрыть выпадающий список
                countryDropdown.classList.add('form__dropdown--hidden');
            });
        });

        // Скрыть dropdown при клике вне его области
        document.addEventListener('click', function (e) {
            if (!countryButton.contains(e.target)) {
                countryDropdown.classList.add('form__dropdown--hidden');
            }
        });
    }

    function inputHandler(masks, max, event) {
        const c = event.target;
        let v = c.value.replace(/\D/g, '');

        // let vArr = v.split('');
        // if (vArr[0] !== '7') {
        //     vArr[0] = '7';
        //     v = vArr.join('');
        // }

        const m = c.value.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    }
}