const password = document.querySelector('#input-password');
const passwordConfirm = document.querySelector('#input-password-confirm');

if(password && passwordConfirm) {
    const form = password.closest('.form');
    const labelConfirm = passwordConfirm.closest('.label');

    form.addEventListener('submit', function(event) {
        console.log(password.value)
        console.log(passwordConfirm.value)
        // Проверка на совпадение паролей
        if (password.value !== passwordConfirm.value) {
            event.preventDefault();
            passwordConfirm.value = ''; // Очищаем поле подтверждения пароля
            passwordConfirm.value = 'Пароли не совпадают';
            passwordConfirm.setAttribute('type', 'text');
            labelConfirm.classList.add('label--alert'); // Добавляем класс alert
        }
    });
    passwordConfirm.addEventListener('input', function(event) {
        if (labelConfirm.classList.contains('label--alert')) {
            this.value = '';
            this.setAttribute('type', 'password');
            labelConfirm.classList.remove('label--alert')
        }
    })
    passwordConfirm.addEventListener('click', function(event) {
        if (labelConfirm.classList.contains('label--alert')) {
            this.value = '';
            this.setAttribute('type', 'password');
            labelConfirm.classList.remove('label--alert')
        }
    })
}
