import { disableScroll } from '../functions/disable-scroll.js';
import { enableScroll } from '../functions/enable-scroll.js';

(function(){
  const burger = document?.querySelector('[data-burger]');
  const search = document?.querySelector('[data-search]');
  const menu = document?.querySelector('[data-menu]');
  const close = document?.querySelectorAll('[data-close]');
  const nav = document?.querySelector('.header .nav');
  const searchBlock = document?.querySelector('.header .search');
  let menuItems = document?.querySelectorAll('[data-menu-item]');
  const overlay = document?.querySelector('[data-menu-overlay]');

  burger?.addEventListener('click', (e) => {
    burger?.classList.toggle('burger--active');
    menu?.classList.toggle('menu--active');
    nav?.classList.toggle('header__nav--active');

    cancelMenuActive();

    if (menu?.classList.contains('menu--active')) {
      setAttribute(burger, 'true', 'Закрыть меню');
      disableScroll();
    } else {
      setAttribute(burger, 'false', 'Открыть меню');
      enableScroll();
    }
  });
  search?.addEventListener('click', (e) => {
    search?.classList.toggle('search--active');
    menu?.classList.toggle('search--active');
    searchBlock?.classList.toggle('search--active');

    if (search?.classList.contains('search--active')) {
      setAttribute(search, 'true', 'Закрыть поиск');
      disableScroll();
    } else {
      setAttribute(search, 'false', 'Открыть поиск');
      enableScroll();
    }
  });

  overlay?.addEventListener('click', () => {
    if (event.target.closest('[data-hold]')) return
    cancelMenuActive();
    cancelOverlay(burger, 'Открыть меню', 'burger--active', nav, 'header__nav--active');
    cancelOverlay(search, 'Открыть поиск', 'search--active', searchBlock, 'search--active');
    menu.classList.remove('menu--active');
    menu.classList.remove('search--active');
    enableScroll();
  });
  close?.forEach(el => {
    el.addEventListener('click', () => {
      if (event.target.closest('[data-hold]')) return
      cancelMenuActive();
      cancelOverlay(burger, 'Открыть меню', 'burger--active', nav, 'header__nav--active');
      cancelOverlay(search, 'Открыть поиск', 'search--active', searchBlock, 'search--active');
      menu.classList.remove('menu--active');
      menu.classList.remove('search--active');
      enableScroll();
    });
  })

  menuItems?.forEach(el => {
    el.addEventListener('click', () => {
      cancelOverlay(burger, 'Открыть меню', 'burger--active', nav, 'header__nav--active');
      cancelOverlay(search, 'Открыть поиск', 'search--active', searchBlock, 'search--active');
      menu.classList.remove('menu--active');
      enableScroll();
    });
  });

  function cancelMenuActive() {
    const moreActive = document?.querySelectorAll('.nav__item--active');
    moreActive.forEach(item => {
      item.classList.remove('nav__item--active');
    })
  }
  function cancelOverlay(btn, btnLabel, btnClassActive, block, blockClassActive) {
    setAttribute(btn, 'false', btnLabel);
    btn.classList.remove(btnClassActive);
    block?.classList.remove(blockClassActive);
  }
  function setAttribute(btn, expanded, label) {
    btn?.setAttribute('aria-expanded', expanded);
    btn?.setAttribute('aria-label', label);
  }
})();
