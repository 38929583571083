const labelDate = document.querySelectorAll('.label--date');

if(labelDate.length) {
    labelDate.forEach(item => {
        const inputDate = item.querySelector('input');

        inputDate.addEventListener('click', function() {
            this.setAttribute('type', 'date');
            this.showPicker();
        });
    })

}

