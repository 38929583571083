const timeout = document.querySelector('.auth__item--timeout');

function startCountdown() {

    if (!timeout) return; // Проверка, существует ли элемент

    const timer = timeout.querySelector('.auth__timer');
    const descr = timeout.querySelector('.auth__descr');
    const link = timeout.querySelector('.auth__link');

    // Парсим начальное время из элемента
    let [minutes, seconds] = timer.textContent.split(":").map(Number);

    // Функция обратного отсчета
    const countdown = setInterval(() => {
        if (seconds === 0) {
            if (minutes === 0) {
                clearInterval(countdown); // Остановка таймера
                descr.style.display = "none"; // Скрытие элемента
                link.style.display = "block";
                return;
            } else {
                minutes--; // Уменьшить минуты
                seconds = 59; // Сбросить секунды до 59
            }
        } else {
            seconds--; // Уменьшить секунды
        }

        // Форматируем и рендерим оставшееся время
        timer.textContent = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }, 1000); // Интервал в 1 секунду
}

startCountdown();