const mapSelector = document.querySelector('#map');
const targetMapButton = document.querySelectorAll('[data-map]');

functionMap();

function functionMap() {
    window.map = null;

    if (!mapSelector) {
        return;
    }

    initMap();

    async function initMap() {
        await ymaps3.ready;

        const {YMap, YMapDefaultSchemeLayer, YMapMarker, YMapLayer, YMapFeatureDataSource, GeoCollectionBounds} = ymaps3;
        const {YMapClusterer, clusterByGrid} = await ymaps3.import('@yandex/ymaps3-clusterer@0.0.1');

        const coordinates = Array.from(targetMapButton).map(item => {
            const dataMap = item.dataset.map;
            const [x, y] = dataMap.split(',').map(coord => parseFloat(coord.trim()));
            return [y, x];
        });

        const map = new YMap(mapSelector, {location: {center: coordinates[0], zoom: 11}});
        map
            .addChild(new YMapDefaultSchemeLayer())
            .addChild(new YMapFeatureDataSource({id: 'my-source'}))
            .addChild(new YMapLayer({source: 'my-source', type: 'markers', zIndex: 1800}));

        const contentPin = document.createElement('div');
        contentPin.classList.add('map__marker')
        contentPin.innerHTML = '<img width="17" height="22.5" src="./img/favicon-light.png" alt="icon" />';

        const marker = (feature) => {
            contentPin.setAttribute('data-map', feature.geometry.coordinates.join(', '))
            return new ymaps3.YMapMarker(
                {
                    coordinates: feature.geometry.coordinates,
                    source: 'my-source'
                },
                contentPin.cloneNode(true)
            );
        }

        const cluster = (coordinates, features) =>
            new ymaps3.YMapMarker(
                {
                    coordinates,
                    source: 'my-source'
                },
                circle(features.length).cloneNode(true)
            );

        function circle(count) {
            const circle = document.createElement('div');
            circle.classList.add('circle');
            circle.innerHTML = `
            <div class="circle-content"><span class="circle-text">${count}</span></div>`;
            return circle;
        }

        const points = coordinates.map((lnglat, i) => ({
            type: 'Feature',
            id: i,
            geometry: {coordinates: lnglat},
            properties: {
                name: '',
            }
        }))

        const clusterer = new YMapClusterer({
            method: clusterByGrid({gridSize: 500}),
            features: points,
            marker,
            cluster,
        });

        map.addChild(clusterer);
        // map.setLocation({
        //     bounds: coordinates
        // });

        targetMapButton.forEach(item => {
            item.addEventListener('click', () => {
                const [y, x] = item.dataset.map.split(',').map(coord => parseFloat(coord.trim()));
                map.setLocation({center: [x, y], duration: 1000, zoom: 16});
            })
        })
    }
}
