const label = document.querySelectorAll('.label');

if(label.length) {
    label.forEach(item => {
        const input = item.querySelector('input');

        input.addEventListener('input', function() {
            if (this.value !== '') {
                item.classList.add('label--active');
            } else {
                item.classList.remove('label--active');
            }
        });
    })

}