import { throttle } from '../functions/throttle.js';
import { isElementInViewport } from '../functions/isElementInViewport.js';

const playVideo = document?.querySelectorAll('.play__video');
const playButton = document?.querySelectorAll('.play__button');

if (playVideo.length > 0) {
   let throttleUpdateSource = throttle(() => {
      updateSource();
   });

   window.addEventListener('DOMContentLoaded', updateSource);
   window.addEventListener('scroll', throttleUpdateSource);
   window.addEventListener('resize', throttleUpdateSource);

   function updateSource() {
      playVideo.forEach((video) => {
         if (isElementInViewport(video.closest('section'))) {
            const isMobile = window.innerWidth <= 768;
            const source = document.querySelectorAll('.play__video source');

            source.forEach((source) => {
               const newSrc = isMobile ? source.getAttribute('data-mobile-src') : source.getAttribute('data-desktop-src');
               source.src = newSrc;
            });

            video.load();
         }
      });
   }
}

if (playButton.length > 0) {
   let isTouchDevice = false;
   window.addEventListener(
      'touchstart',
      () => {
         isTouchDevice = true;
      },
      { once: true }
   );

   playButton.forEach((item) => {
      const video = item.parentNode.querySelector('video');
      item.addEventListener('mouseenter', function () {
         if (isTouchDevice) {
            return;
         }
         controlVideo(video);
      });
      item.addEventListener('mouseleave', function () {
         if (isTouchDevice) {
            return;
         }
         controlVideo(video);
      });
      item.addEventListener('click', function () {
         if (isTouchDevice) {
            controlVideo(video);
            item.classList.toggle('play__button--active');
         }
      });
   });

   function controlVideo(video) {
      if (video && video.paused) {
         video.play();
      } else if (video) {
         video.pause();
      }
   }
}
