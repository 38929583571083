const menuItems = document?.querySelectorAll('.nav__item--more');

let isTouchDevice = false;
window.addEventListener('touchstart', () => { isTouchDevice = true; }, { once: true });

menuItems.forEach(item => {
    item.addEventListener('click', function(event) {

        if (isTouchDevice) {

            if (item.classList.contains('nav__item--active')) {
                return;
            }

            item.closest('.nav__list').querySelector('& > .nav__item--active')?.classList.remove('nav__item--active');
            event.preventDefault();
            item.classList.add('nav__item--active');
        }
    });
});